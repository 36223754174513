import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './LoadingOverlay.css';
import { topLeftToBottomRight, centerToEdges, spiralPattern, wavePattern, radialPattern, noAnimationPattern } from './animationPatterns';

const gridCount = 10;

const getDelay = (row, col, size, pattern) => {
  switch (pattern) {
    case 'centerToEdges':
      return centerToEdges(row, col, size);
    case 'spiralPattern':
      return spiralPattern(row, col, size);
    case 'wavePattern':
      return wavePattern(row, col, size);
    case 'radialPattern':
      return radialPattern(row, col, size);
    case 'topLeftToBottomRight':
      return topLeftToBottomRight(row, col, size);
    case 'noAnimation':
      return noAnimationPattern();
    default:
      return topLeftToBottomRight(row, col, size);
  }
};

const LoadingOverlay = ({ imageUrl, pattern = 'noAnimation', onTakeAnother, handleCapture }) => {
  // Generate the grid squares
  const squares = [];
  for (let i = 0; i < gridCount * gridCount; i++) {
    let row = Math.floor(i / gridCount);
    let col = i % gridCount;
    let delay = getDelay(row, col, gridCount, pattern);
    squares.push(
      <div
        key={i}
        className="grid-square"
        style={{ animationDelay: `${delay}s` }}
      ></div>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(255, 255, 255, 1)"
      zIndex="modal"
    >
      <Typography variant="h4" gutterBottom>
        Thinking
        <span className="dot-container">
          <span className="animated-dot">.</span>
          <span className="animated-dot">.</span>
          <span className="animated-dot">.</span>
        </span>
      </Typography>

      {imageUrl && (
        <div className="image-preview-container">
          {pattern !== 'noAnimation' && <div className="grid-overlay">{squares}</div>}
          <img src={imageUrl} alt="Preview" className="image-preview" />
        </div>
      )}

      <Button
          variant="outlined"
          color="primary"
          startIcon={<CameraAltIcon />}
          onClick={onTakeAnother}
          sx={{ mt: 2 }}
      >
        Take another
      </Button>
    </Box>
  );
};

export default LoadingOverlay;