import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Grid, Box, Typography, Card, CardContent, IconButton,
  Link, Divider, LinearProgress, CardMedia
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth } from '../Authentication/AuthContext';

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const { id: orderId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderDetails = async () => {
            if (!orderId) return;
            try {
                const response = await fetch(`/order/${orderId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrderDetails(data.order);
                console.log('Order details:', data);
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        fetchOrderDetails();
    }, [orderId, token]);

    const handleBack = () => {
        navigate(-1);
    };

    if (!orderDetails) {
        return <div>Loading...</div>;
    }

    return (
        <Container maxWidth="xl">
          <Box sx={{ my: 4 }}>
            <IconButton onClick={handleBack} aria-label="back">
              <ArrowBackIcon />
            </IconButton>
            {orderDetails.thumbnailUrl && (
              <CardMedia
                component="img"
                height="140"
                image={orderDetails.thumbnailUrl}
                alt="Order Thumbnail"
                sx={{ maxWidth: 140, borderRadius: '4px', float: 'right', ml: 2 }}
              />
            )}
            <Typography variant="h4" gutterBottom>Order Details</Typography>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" gutterBottom>
                        Total Price: ${orderDetails.totalPriceInDollars}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      <LocalShippingIcon sx={{ verticalAlign: 'bottom', mr: 1 }} />
                      Status: {orderDetails.deliveryInfo.status}
                    </Typography>
                    {orderDetails.deliveryInfo.complete ? (
                      <Typography variant="body1" gutterBottom>
                        <CheckCircleOutlineIcon sx={{ verticalAlign: 'bottom', mr: 1 }} />
                        Completed
                      </Typography>
                    ) : (
                      <LinearProgress />
                    )}
                    {orderDetails.deliveryInfo.undeliverable_reason && (
                      <Typography variant="body2" gutterBottom>
                        Reason: {orderDetails.deliveryInfo.undeliverable_reason}
                      </Typography>
                    )}
                    {orderDetails.deliveryInfo.deliverable_action && (
                      <Typography variant="body2" gutterBottom>
                        Instructions: {orderDetails.deliveryInfo.deliverable_action}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Tracking Information
                    </Typography>
                    <Link href={orderDetails.deliveryInfo.tracking_url} target="_blank" rel="noopener">
                      {orderDetails.deliveryInfo.tracking_url}
                    </Link>
                    {orderDetails.deliveryInfo.undeliverable_action && (
                      <>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">
                          Undeliverable: {orderDetails.deliveryInfo.undeliverable_action}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      );
    };
    
    export default OrderDetails;