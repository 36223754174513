import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, Box, Container, Button, Card, CardContent, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../Authentication/AuthContext';
import ImageSection from '../components/ImageSection';
import DescriptionSection from '../components/DescriptionSection';
import AddressSection from '../components/AddressSection';
import EstimatedDimensionsSection from '../components/EstimatedDimensionsSection';
import DetailsSection from '../components/DetailsSection';
const TAG = '[ListingDetail]';

const ListingDetail = () => {
    const [detail, setDetail] = useState(null);
    const { id: userId, token } = useAuth();
    const { id: listingId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDetail = async () => {
            try {
                // Include userId in the request
                console.log(TAG, userId);
                const response = await axios.get(`/listing/${listingId}`, { params: { userId } });
                setDetail(response.data);
            } catch (error) {
                console.error('Error fetching listing detail:', error);
            }
        };
    
        if (listingId) {
            fetchDetail();
        }
    }, [listingId, token]);    

    const createOrder = async (orderData) => {
        if (token) {
            try {
                const response = await axios.post('/order', orderData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return response.data.orderId;
            } catch (error) {
                console.error('Error creating order:', error);
                alert('Error creating order');
                return null;
            }
        } else {
            try {
                const response = await axios.post('/create-inactive-order', orderData);
                console.log('Inactive order created:', response.data);
                return response.data.orderId;
            } catch (error) {
                console.error('Error creating order:', error);
                alert('Error creating order');
                return null;
            }
        }
    };
    
    const handleDelete = async () => {
        try {
            await axios.delete(`/listing/${listingId}?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            navigate('/listings');
        } catch (error) {
            console.error('Error deleting listing:', error);
            alert('Error deleting listing');
        }
    };    

    const handleBuy = async () => {
        const orderData = {
            ...detail,
            listingId,
            status: 'unconfirmed',
            buyerId: userId
        };
        console.log('Order data:', orderData);

        const orderId = await createOrder(orderData);

        if (!token) {
            navigate('/login', { state: { from: 'listing-detail', orderId } });
            return;
        }

        if (orderId) {
            navigate('/order-confirmation', { state: { orderId } });
        }
    };

    const handleBack = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/listings');
        }
    };

    if (!detail) {
        return <div>Loading...</div>;
    }


    return (
        <Container maxWidth="xl">
            <Box sx={{ my: 4 }}>
                <IconButton onClick={handleBack} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>

                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <ImageSection
                                    shortDescription={detail.short_description}
                                    imageUrls={detail.imageUrls} />
                                {detail.sellerId === userId ? (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleDelete}
                                        sx={{ mt: 2, width: '100%' }}
                                    >
                                        Delete Listing
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleBuy}
                                        sx={{ mt: 2, width: '100%' }}
                                    >
                                        Buy Now - ${detail.totalPriceInDollars}
                                    </Button>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Description Section */}
                    <Grid item xs={12}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <DescriptionSection description={detail.description} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Address and Estimated Dimensions Section */}
                    <Grid container item spacing={2} xs={12}>                        
                        {detail.dropoffAddress?.street_address && (
                            <Grid item xs={12} sm={6}>
                                <Card elevation={3} sx={{ mb: 4 }}>
                                    <CardContent>
                                        <AddressSection
                                            address={detail.dropoffAddress}
                                            heading="Dropoff"
                                            editMode={false}
                                            handleAddressChange={() => {}}
                                            toggleEditMode={() => {}}
                                            canEdit={false}
                                            hideStreet={true}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={6}>
                            <Card elevation={3} sx={{ mb: 4 }}>
                                <CardContent>
                                    <EstimatedDimensionsSection dimensions={detail.estimated_dimensions} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Features and Condition Section */}
                    <Grid item xs={12} sm={6}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <DetailsSection section="Features and Condition" content={detail.features_and_condition} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Functionality and Documentation Section */}
                    <Grid item xs={12} sm={6}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <DetailsSection section="Functionality and Documentation" content={detail.functionality_and_documentation} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Authenticity and Style Section */}
                    <Grid item xs={12} sm={6}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <DetailsSection section="Authenticity and Style" content={detail.authenticity_and_style} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Market Insights Section */}
                    <Grid item xs={12} sm={6}>
                        <Card elevation={3} sx={{ mb: 4 }}>
                            <CardContent>
                                <DetailsSection section="Market Insights" content={detail.market_insights} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default ListingDetail;
