// Register.js
import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, Card, CardContent, Grid, Checkbox, FormControlLabel, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from './AuthContext';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
const TAG = "[Register]";

const Register = () => {

    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isSellerFromLocation = location.state?.isSeller || false;
    const listingId = location.state?.listingId || false;
    const orderId = location.state?.orderId || false;

    // State for user input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [street, setStreet] = useState('');
    const [secondaryStreet, setSecondaryStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [isSeller, setIsSeller] = useState(isSellerFromLocation);

    // State for form errors
    const [formErrors, setFormErrors] = useState({});

    const handleBack = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const stripe = useStripe();
    const elements = useElements();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({}); // Reset form errors
    
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }
    
        // Format the date of birth
        const dobFormatted = dateOfBirth ? {
            day: dateOfBirth.split('-')[2],
            month: dateOfBirth.split('-')[1],
            year: dateOfBirth.split('-')[0],
        } : {};
    
        if (!stripe || !elements) {
            console.log(TAG, 'Stripe has not loaded');
            return;
        }
    
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
    
        if (error) {
            console.error(TAG, 'Error:', error);
            alert("Error creating payment method: " + error.message);
            return;
        }
    
        // Step 1: Create customer on the backend
        let customerResponse = await fetch('/stripe/create-customer', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        });
    
        if (!customerResponse.ok) {
            console.error(TAG, 'Error creating customer');
            return;
        }
    
        customerResponse = await customerResponse.json();
        const customerId = customerResponse.customer.id;
    
        // Step 2: Attach the payment method to the customer on the backend
        let attachResponse = await fetch('/stripe/attach-payment-method', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                paymentMethodId: paymentMethod.id,
                customerId
            })
        });
    
        if (!attachResponse.ok) {
            const attachError = await attachResponse.json();
            console.error(TAG, 'Error attaching payment method:', attachError);
            alert("Error attaching payment method: " + attachError.message);
            return;
        }
    
        // Step 3: Continue with the registration on the backend
        const registrationResponse = await fetch('/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                password,
                firstName,
                lastName,
                dateOfBirth: dobFormatted,
                phoneNumber,
                userType: isSeller ? 'seller' : 'buyer',
                address: {
                    street_address: [street, secondaryStreet],
                    city,
                    state,
                    zip,
                    country
                },
                listingId: listingId ? listingId : null,
                stripeCustomerId: customerId,
                paymentMethodId: paymentMethod.id
            })
        });
    
        const registrationData = await registrationResponse.json();
        if (registrationResponse.ok) {
            // Redirect to the next step or show success message
            console.log(TAG, 'Registration successful', registrationData);
            login(registrationData.token, registrationData.buyerResponse?.stripeCustomerId, registrationData.email, registrationData.id, registrationData.address);

            if (registrationData?.sellerResponse?.accountLinkUrl) {
                window.location.href = registrationData.sellerResponse.accountLinkUrl;
            } else if (orderId) {
                navigate('/order-confirmation', { state: { orderId } });
            } else if (listingId) {
                navigate('/sell', { state: { listingId } });
            } else {
                navigate('/login');
            }
        } else {
            console.error(TAG, 'Registration failed:', registrationData);
            alert("An error occurred during registration: " + registrationData.message);
        }
    };       

    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <IconButton onClick={handleBack} aria-label="back" sx={{ marginRight: 'auto' }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" component="h1" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        Register
                    </Typography>
                    <Box sx={{ width: 48 }} />
                </Box>

                {/* User Information Section */}
                <Card raised sx={{ width: '100%', mb: 2 }}> {/* Set a consistent width */}
                    <CardContent>
                        <Grid container spacing={2}>
                            {/* First and Last Name Fields */}
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.firstName}
                                    helperText={formErrors.firstName}
                                    fullWidth
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.lastName}
                                    helperText={formErrors.lastName}
                                    fullWidth
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            
                {/* Address Information Section */}
                <Card raised sx={{ minWidth: 275, mb: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!formErrors.street}
                                    helperText={formErrors.street}
                                    fullWidth
                                    label="Street"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!formErrors.secondaryStreet}
                                    helperText={formErrors.secondaryStreet}
                                    fullWidth
                                    label="Secondary Address (Unit, Suite, etc.)"
                                    value={secondaryStreet}
                                    onChange={(e) => setSecondaryStreet(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.city}
                                    helperText={formErrors.city}
                                    fullWidth
                                    label="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.state}
                                    helperText={formErrors.state}
                                    fullWidth
                                    label="State"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.zip}
                                    helperText={formErrors.zip}
                                    fullWidth
                                    label="Zip Code"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.country}
                                    helperText={formErrors.country}
                                    fullWidth
                                    label="Country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card raised sx={{ width: '100%', mb: 2 }}>
                    <CardContent>
                        <CardElement />
                    </CardContent>
                </Card>

                {/* Payment Information Section */}
                <Card raised sx={{ width: '100%', mb: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!formErrors.phoneNumber}
                                    helperText={formErrors.phoneNumber}
                                    fullWidth
                                    label="Phone Number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </Grid>
                            {/* Date of Birth Field */}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="Date of Birth"
                                    value={dateOfBirth}
                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox checked={isSeller} onChange={(e) => setIsSeller(e.target.checked)} />}
                            label="I plan to sell and receive money on the marketplace"
                        />
                    </CardContent>
                </Card>
        
                {/* Submit Button */}
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                >
                    Register
                </Button>
            </Box>
        </Container>
    );
    
    
};

export default Register;
