// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoadingOverlay.css */
.image-preview-container {
  position: relative;
  width: 300px; /* Set a fixed width or max-width */
  height: 300px; /* Set a fixed height or max-height */
  margin-top: 20px;
  margin-bottom: 20px;
}

.grid-overlay {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10x10 grid */
  grid-template-rows: repeat(10, 1fr);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.grid-square {
  background-color: #fff;
  animation: fadeOut 2s infinite ease-in-out;
}


@keyframes fadeOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.animated-dot {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
}

.animated-dot:nth-child(1) {
  animation-delay: 0s;
}

.animated-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.animated-dot:nth-child(3) {
  animation-delay: 0.6s;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingOverlay/LoadingOverlay.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,kBAAkB;EAClB,YAAY,EAAE,mCAAmC;EACjD,aAAa,EAAE,qCAAqC;EACpD,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sCAAsC,EAAE,eAAe;EACvD,mCAAmC;EACnC,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,0CAA0C;AAC5C;;;AAGA;EACE,WAAW,UAAU,EAAE;EACvB,MAAM,UAAU,EAAE;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/* LoadingOverlay.css */\n.image-preview-container {\n  position: relative;\n  width: 300px; /* Set a fixed width or max-width */\n  height: 300px; /* Set a fixed height or max-height */\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.grid-overlay {\n  display: grid;\n  grid-template-columns: repeat(10, 1fr); /* 10x10 grid */\n  grid-template-rows: repeat(10, 1fr);\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n}\n\n.grid-square {\n  background-color: #fff;\n  animation: fadeOut 2s infinite ease-in-out;\n}\n\n\n@keyframes fadeOut {\n  0%, 100% { opacity: 1; }\n  50% { opacity: 0; }\n}\n\n.image-preview {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 4px;\n}\n\n.animated-dot {\n  animation: blink 1.4s infinite;\n  animation-fill-mode: both;\n}\n\n.animated-dot:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.animated-dot:nth-child(2) {\n  animation-delay: 0.3s;\n}\n\n.animated-dot:nth-child(3) {\n  animation-delay: 0.6s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
