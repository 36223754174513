import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
const TAG = '[UberWebhook]';

const UberWebhook = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            console.log(`${TAG} SearchParams:`, searchParams.toString());

            // Construct the parameters for the webhook registration request
            const params = new URLSearchParams();
            
            // You can add additional parameters that Uber might require for the webhook registration
            const eventTypes = ['event.delivery_status', 'event.courier_update', 'event.refund_request'];
            params.append('eventTypes', JSON.stringify(eventTypes));

            // Add other relevant parameters from the searchParams if available
            for (const [key, value] of searchParams) {
                params.append(key, value);
            }

            // Make the request to your backend to register the webhook with the parameters
            const response = await fetch(`/uber/webhook?${params.toString()}`, {
                method: 'POST', // Assuming your backend expects a POST request
            });
            const data = await response.json();
            console.log(`${TAG} Response Data:`, data);

            // Redirect to login or another appropriate page after handling the webhook registration
            navigate('/login');
        };

        fetchData();
    }, [searchParams, navigate]);

    return <div>Processing webhook registration...</div>;
};

export default UberWebhook;
