// Define the grid size
const gridCount = 10;

export const topLeftToBottomRight = (row, col) => (row + col) * (1.2 / gridCount);

export const centerToEdges = (row, col) => Math.abs(row - gridCount / 2) + Math.abs(col - gridCount / 2);

export const spiralPattern = (row, col, size) => {
  let x = 0, y = 0, dx = 0, dy = -1;
  let spiralDelay = [];
  let maxI = size * size;
  let half = Math.floor(size / 2);

  // Initialize the 2D array
  for (let i = 0; i < size; i++) {
    spiralDelay[i] = [];
  }

  for (let i = 0; i < maxI; i++) {
    if ((-half <= x) && (x < half) && (-half <= y) && (y < half)) {
      spiralDelay[y + half][x + half] = i; // Visit the square
    }

    if ((x === y) || ((x < 0) && (x === -y)) || ((x > 0) && (x === 1 - y))) {
      [dx, dy] = [-dy, dx]; // Change direction
    }

    x += dx;
    y += dy;
  }

  // Ensure row and col are within the bounds of the array
  if (row >= 0 && row < size && col >= 0 && col < size) {
    return (spiralDelay[row][col] / maxI) * 1.4; // Normalize delay
  }
  
  return 0; // Fallback in case of out-of-bounds
};

export const wavePattern = (row, col, size) => {
  const wave = Math.sin((col / size) * 2 * Math.PI) * (size / 4);
  return (row + size / 2 + wave) % size * (1.4 / size);
};

export const radialPattern = (row, col, size) => {
  let centerX = (size - 1) / 2;
  let centerY = (size - 1) / 2;
  let deltaX = row - centerX;
  let deltaY = col - centerY;
  let distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  let maxDistance = Math.sqrt(centerX * centerX + centerY * centerY); 
  return (distance / maxDistance) * 1.4;
};

export const noAnimationPattern = () => 0;
