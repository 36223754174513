import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Container, Box } from '@mui/material';
import { useAuth } from '../Authentication/AuthContext';
import LoadingDots from '../components/LoadingDots/LoadingDots';
import ImageSection from '../components/ImageSection';

const Listings = () => {
    const [listings, setListings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const response = await axios.get('/listings');
                // Set listings directly, with loadingPrice set to false as there's no additional price calculation
                setListings(response.data.map(listing => ({ ...listing, loadingPrice: false })));
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };

        fetchListings();
    }, []);

    const goToDetail = (uuid) => {
        if (uuid) {
            navigate(`/listing/${uuid}`);
        } else {
            console.error('UUID is undefined, cannot navigate to details');
        }
    };

    return (
        <Container maxWidth="xl" sx={{ marginTop: 4 }}>
            <Grid container spacing={4} justifyContent="center">
                {listings.map((listing) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={listing.id} onClick={() => goToDetail(listing.id)}>
                        <Card sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardMedia
                                component="img"
                                sx={{ objectFit: 'contain', height: 200 }}
                                image={listing.thumbnailUrl || listing.imageUrl}
                                alt={listing.title}
                                loading="lazy"
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'medium' }}>
                                    {listing.title}
                                </Typography>
                                <Typography variant="body1" color="text.secondary" sx={{ my: 1 }}>
                                    {listing.short_description}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }}>
                                    {listing.loadingPrice ? (
                                        <LoadingDots />
                                    ) : (
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'regular', textAlign: 'center', color: 'text.primary', mt: 2 }}>
                                            ${listing.price ? Math.round(listing.price) : 'N/A'}
                                        </Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Listings;
