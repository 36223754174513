import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const StripeRegisterResponse = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            // Extract listingId from searchParams
            const listingId = searchParams.get('listingId');
            console.log("SearchParams:", searchParams.toString());
            console.log("Listing UUID:", listingId);

            // Make sure to include listingId in the request if it's available
            const params = new URLSearchParams();
            if (listingId) {
                params.append('listingId', listingId);
            }

            // Append other parameters as required
            const userId = searchParams.get('userId');
            if (userId) {
                params.append('userId', userId);
            }

            const response = await fetch(`/register-stripe?${params.toString()}`);
            const data = await response.json();
            console.log("Response Data:", data);

            if (listingId) {
                navigate('/sell', { state: { listingId } });
            } else {
                navigate('/login');
            }
        };

        fetchData();
    }, [searchParams, navigate]);

    return <div>Processing...</div>;
};

export default StripeRegisterResponse;
