import React from 'react';
import { Typography, Box } from '@mui/material';

const EstimatedDimensionsSection = ({ dimensions }) => {
  const formattedDimensions = `${dimensions.length} x ${dimensions.width} x ${dimensions.height} centimeters, ${dimensions.weight} grams`;

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
        Dimensions
      </Typography>
      <Typography variant="body2">{formattedDimensions}</Typography>
    </Box>
  );
};

export default EstimatedDimensionsSection;
