// LoadingDots.js
import React from 'react';
import { Box } from '@mui/material';
import './LoadingDots.css'; // Import the CSS for animation

const LoadingDots = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <span className="animated-dot">.</span>
      <span className="animated-dot">.</span>
      <span className="animated-dot">.</span>
    </Box>
  );
};

export default LoadingDots;
