// Profile.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthContext';
import { Container, Grid, Box, Typography, CardMedia, Card, CardContent, Link as MuiLink } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import CardActions from '@mui/material/CardActions';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

const TAG = "[Profile]";

const Profile = () => {
  const navigate = useNavigate();
  const { token, logout, id } = useAuth();
  const [listings, setListings] = useState([]);
  const [orders, setOrders] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await fetch(`/listings/${id}`);
        const data = await response.json();
        setListings(data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    const fetchOrders = async () => {
      try {
        const response = await fetch(`/order/user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    
    const fetchUserInfo = async () => {
      try {
          const response = await fetch('/user', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          const data = await response.json();
          setUserInfo(data);
          console.log(TAG, 'User info:', data);
      } catch (error) {
          console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
    fetchListings();
    fetchOrders();
  }, [id]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(`/users/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      if (response.ok) {
        // User deleted successfully, perform any cleanup and redirect
        console.log('User deleted successfully');
        await logout();
        navigate('/login');
      } else {
        // Handle any errors, such as user not found
        console.error('Error deleting user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  // Function to open the confirmation dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  
  // Function to close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const totalPrice = listings.reduce((acc, listing) => acc + listing.price, 0);

  if (!token) {
    navigate('/login');
    return;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="div" sx={{ mt: 4, mb: 2 }}>
            Orders
        </Typography>
        <Grid container spacing={4}>
          {orders.map((order) => (
            <Grid item xs={12} sm={6} md={4} key={order.id}>
              <Card
                sx={{ cursor: 'pointer', ':hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', height: '100%' }}
                onClick={() => navigate(`/order/${order.id}`)}
              >
                <CardMedia
                  component="img"
                  sx={{ height: 140, objectFit: 'cover', bgcolor: 'background.default' }}
                  image={order.thumbnailUrl}
                  alt="Order Image Placeholder"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {order.deliveryInfo
                      ? order.deliveryInfo.complete
                        ? 'Delivery Complete'
                        : 'Delivery Started'
                      : 'Pending'}
                  </Typography>
                  {order.totalPrice && (
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Total: ${order.totalPrice.toFixed(2)}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4" component="div" sx={{ mb: 4 }}>
          Listings
          <Typography
            component="span"
            variant="subtitle1"
            sx={{
              ml: 2,
              fontWeight: 'bold',
              color: 'text.secondary',
            }}
          >
            {totalPrice && totalPrice.toFixed(2)}
          </Typography>
        </Typography>
        <Grid container spacing={4}>
          {listings.map((listing) => (
            <Grid item xs={12} sm={6} md={4} key={listing.id} onClick={() => navigate(`/listing/${listing.id}`)}>
              <Card sx={{ cursor: 'pointer', ':hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardMedia
                  component="img"
                  sx={{ height: 140, objectFit: 'cover', bgcolor: 'background.default' }}
                  image={listing.thumbnailUrl || listing.imageUrl}
                  alt={listing.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {listing.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {listing.short_description}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    ${listing.price.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid>
        <Card sx={{ my: 4 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {userInfo.firstName} {userInfo.lastName}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <EmailIcon sx={{ mr: 1 }} /> {userInfo.email}
          </Typography>
          {userInfo.address && (
            <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <LocationOnIcon sx={{ mr: 1 }} />
              {userInfo.address.street_address.join(' ')},
              {userInfo.address.city},
              {userInfo.address.state},
              {userInfo.address.zip},
              {userInfo.address.country}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button size="small" startIcon={<DeleteIcon />} onClick={handleOpenDialog} color="error">
            Delete Account
          </Button>
        </CardActions>
      </Card>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete your account?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

        </Grid>

        <Box sx={{
          position: 'fixed', // Fixed position to place it at the bottom of the view
          bottom: 0, // Align to the bottom
          left: 0,
          right: 0,
          bgcolor: 'background.paper', // Use the theme's background color
          py: 2, // Vertical padding
          borderTop: 1, // Add a top border for separation
          borderColor: 'divider' // Use the theme's divider color
        }}>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <MuiLink
              component="button"
              onClick={handleLogout}
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  textDecoration: 'none',
                  color: 'secondary.main', // Change color on hover for visual feedback
                },
              }}
            >
              <LogoutIcon sx={{ mr: 1 }} fontSize="small" />
              Logout
            </MuiLink>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
