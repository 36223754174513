import React from 'react';

const Payment = () => {
    return (
        <div>
            Payment Page
        </div>
    );
}

export default Payment;
