import React from 'react';
import { Typography, TextField, IconButton, Button, Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const AddressSection = ({ address, editMode, handleAddressChange, toggleEditMode, canEdit = true, hideStreet = false, heading = 'Address' }) => {
  const isAddressValid = address && 
    address.street_address && 
    address.street_address.length > 0 && 
    address.city && 
    address.state && 
    address.zip && 
    address.country;

  if (!isAddressValid) {
    return <div>Loading...</div>;
  }

  const displayAddress = hideStreet 
  ? `${address.city}, ${address.state}, ${address.zip}, ${address.country}`
  : `${address.street_address.join(' ')}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}`;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" gutterBottom>{heading}</Typography>
      {editMode ? (
        <Grid container spacing={2}>
          {/* Text fields for editing address */}
          {/* ... */}
        </Grid>
      ) : (
        <Typography variant="body1">{displayAddress}</Typography>
      )}
      {canEdit && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: editMode ? 2 : 0 }}>
          {editMode ? (
            <Button startIcon={<SaveIcon />} size="small" variant="outlined" color="primary" onClick={() => toggleEditMode(false)}>
              Save
            </Button>
          ) : (
            <IconButton size="small" onClick={() => toggleEditMode(true)} color="primary">
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AddressSection;
