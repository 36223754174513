// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.animated-dot {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
  font-size: 2rem; /* Increase font size for larger dots */
  display: inline-block; /* Ensures proper alignment of dots */
}

.animated-dot:nth-child(1) {
  animation-delay: 0s;
}

.animated-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.animated-dot:nth-child(3) {
  animation-delay: 0.6s;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/LoadingDots/LoadingDots.css"],"names":[],"mappings":"AAAA;EACE,WAAW,UAAU,EAAE;EACvB,MAAM,UAAU,EAAE;AACpB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,eAAe,EAAE,uCAAuC;EACxD,qBAAqB,EAAE,qCAAqC;AAC9D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@keyframes blink {\n  0%, 100% { opacity: 0; }\n  50% { opacity: 1; }\n}\n\n.animated-dot {\n  animation: blink 1.4s infinite;\n  animation-fill-mode: both;\n  font-size: 2rem; /* Increase font size for larger dots */\n  display: inline-block; /* Ensures proper alignment of dots */\n}\n\n.animated-dot:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.animated-dot:nth-child(2) {\n  animation-delay: 0.3s;\n}\n\n.animated-dot:nth-child(3) {\n  animation-delay: 0.6s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
