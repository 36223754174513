import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Button, Card, CardContent, CardMedia, Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthContext';
import AddressSection from '../components/AddressSection';
const TAG = "[OrderConfirmation]";

const OrderConfirmation = () => {
    const { state } = useLocation();
    const { token, id, address: userAddress, email } = useAuth();
    const orderId = state?.orderId || false;
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            if (!orderId) return;
    
            setIsLoading(true);
            try {
                console.log(TAG, "user id:", id);
                const orderResponse = await fetch(`/order/${orderId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(TAG, "orderResponse:", orderResponse);
                const orderData = await orderResponse.json();

    
                if (!orderData.success) {
                    setError(orderData.message || 'Failed to fetch order details');
                    return;
                }
    
                const listingId = orderData.order.listingId;
                if (!listingId) {
                    setError('No listing ID found in order details');
                    return;
                }
    
                const listingResponse = await fetch(`/listing/${listingId}?userId=${id}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                const listingData = await listingResponse.json();
                if (listingData) {
                    setOrderDetails({ ...orderData, listing: listingData, dropoffAddress: userAddress, buyerId: id });
                } else {
                    setError('Failed to fetch listing details');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchOrderDetails();
    }, [orderId, token, id, email]);    

    const handleConfirmOrder = async () => {
        if (!orderId || !token) {
            console.error('Order ID or authorization token missing');
            return;
        }
    
        try {
            const response = await fetch(`/order/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    status: 'confirmed',
                    orderDetails
                })
            });
    
            const data = await response.json();
            if (data.success) {
                console.log('Order status updated successfully');
                navigate('/profile');
            } else {
                console.error('Failed to update order status:', data.message);
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };
    

    const handleCancelOrder = () => {
        navigate(-1);
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (isLoading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error}</Typography>;

    console.log(TAG, "orderDetails:", orderDetails);

    return (
        <Container maxWidth="xl">
            <Box sx={{ my: 4 }}>
                <IconButton onClick={handleBack} aria-label="back" sx={{ marginBottom: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" gutterBottom textAlign="center">
                    Order Confirmation
                </Typography>

                {orderDetails ? (
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12}>
                            <Card elevation={3}>
                                <CardMedia
                                    component="img"
                                    image={orderDetails.listing.imageUrls[0]}
                                    alt={orderDetails.listing.short_description}
                                    sx={{ width: '100%', maxHeight: 300, objectFit: 'contain' }}
                                />
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {orderDetails.listing.short_description}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.primary">
                                        ${orderDetails.listing.totalPriceInDollars}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Card elevation={3} sx={{ mb: 4 }}>
                                <CardContent>
                                    <AddressSection
                                        address={orderDetails.listing.pickupAddress}
                                        heading="Pickup"
                                        editMode={false}
                                        canEdit={false}
                                        hideStreet={true}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Card elevation={3} sx={{ mb: 4 }}>
                                <CardContent>
                                    <AddressSection
                                        heading="Dropoff"
                                        address={orderDetails.dropoffAddress}
                                        editMode={false}
                                        canEdit={false}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleConfirmOrder}>
                                    Confirm Order
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    color="secondary" 
                                    onClick={handleCancelOrder}
                                >
                                    Cancel Order
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1" textAlign="center">
                        Loading order details...
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default OrderConfirmation;
