import React, { createContext, useContext, useState } from 'react';

const TAG = '[AuthContext]';

console.log(TAG, "Loading AuthContext.js");
const AuthContext = createContext({
  isAuthenticated: !!localStorage.getItem('needl_token'),
  token: !!localStorage.getItem('needl_token') ? localStorage.getItem('needl_token') : null,
  stripeAccountId: !!localStorage.getItem('needl_stripeAccountId') ? localStorage.getItem('needl_stripeAccountId') : null,
  email: !!localStorage.getItem('needl_email') ? localStorage.getItem('needl_email') : null,
  id: !!localStorage.getItem('needl_uuid') ? localStorage.getItem('needl_uuid') : null,
  address: !!localStorage.getItem('needl_address') ? localStorage.getItem('needl_address') : null,
  login: () => {}, // Adding default noop functions for login and logout
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  // Helper functions for each localStorage item
  const getItem = (key) => {
    const item = localStorage.getItem(key);
    if (item === "undefined" || item === null) {
      return null; // or return undefined; whichever you prefer for your logic
    }
    return item;
  };

  const getToken = () => getItem('needl_token');
  const getStripeAccountId = () => getItem('needl_stripeAccountId');
  const getEmail = () => getItem('needl_email');
  const getId = () => getItem('needl_uuid');
  const getAddress = () => {
    const address = getItem('needl_address');
    return address ? JSON.parse(address) : null;
  };

  const [authState, setAuthState] = useState({
    isAuthenticated: !!getToken(),
    token: getToken(),
    stripeAccountId: getStripeAccountId(),
    email: getEmail(),
    id: getId(),
    address: getAddress()
  });

  const login = (token, stripeAccountId, email, id, address) => {
    localStorage.setItem('needl_token', token);
    localStorage.setItem('needl_stripeAccountId', stripeAccountId);
    localStorage.setItem('needl_email', email);
    localStorage.setItem('needl_uuid', id);
    localStorage.setItem('needl_address', JSON.stringify(address));
    setAuthState(prevState => ({
      ...prevState,
      isAuthenticated: true,
      token,
      stripeAccountId,
      email,
      id,
      address
    }));
  };

  const logout = () => {
    localStorage.removeItem('needl_token');
    localStorage.removeItem('needl_stripeAccountId');
    localStorage.removeItem('needl_email');
    localStorage.removeItem('needl_uuid');
    localStorage.removeItem('needl_address');
    setAuthState({
      isAuthenticated: false,
      token: null,
      stripeAccountId: null,
      email: null,
      id: null,
      address: null
    });
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
