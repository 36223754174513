import React from 'react';

const Cart = () => {
    return (
        <div>
            Cart Page
        </div>
    );
}

export default Cart;
