// App.js is the main component that renders all other components
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Landing from './components/Landing/Landing';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import { AuthProvider, useAuth } from './components/Authentication/AuthContext';
import Listings from './components/Listings/Listings';
import ListingDetail from './components/Listings/ListingDetail';
import OrderConfirmation from './components/Orders/OrderConfirmation';
import OrderDetails from './components/Orders/OrderDetails';
import PostItem from './components/Posting/PostItem';
import Cart from './components/Cart/Cart';
import Checkout from './components/Cart/Checkout';
import Payment from './components/Cart/Payment';
import Notifications from './components/Notifications/Notifications';
import RateTransaction from './components/Ratings/RateTransaction';
import Feedback from './components/Ratings/Feedback';
import Footer from './components/Footer/Footer';
import Sell from './components/Sell/Sell';
import Profile from './components/Profile/Profile';
import StripeRegisterResponse from './components/webhooks/StripeRegisterResponse';
import UberWebhook from './components/webhooks/UberWebhook';
const TAG = "[App]";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
// ProtectedRoute Component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Use the custom hook to get the auth state
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider> {/* Wrap the entire app with AuthProvider */}
        <Router>
          <Header />
          <div style={{ minHeight: '100vh' }}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/listings" element={<Listings />} />
              <Route path="/post-item" element={<PostItem />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/rate" element={<RateTransaction />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/sell" element={<Sell />} />
              <Route path="/listing/:id" element={<ListingDetail />} />
              <Route path="/order-confirmation" element={<OrderConfirmation />} />
              <Route path="/order/:id" element={<OrderDetails />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/register-stripe" element={<StripeRegisterResponse />} />
              <Route path="/uber-webhook" element={<UberWebhook />} />

              {/* Protected Routes */}
              {/* <Route path="/listing/:uuid" element={
                <ProtectedRoute><ListingDetail /></ProtectedRoute>
              } /> */}
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </Elements>
  );
}

export default App;