import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const ImageSection = ({ imageUrls, onImageSelect, shortDescription }) => {
  const inputRef = useRef(null);

  const handleTakeAnother = () => {
    // Reset the file input for a new picture
    inputRef.current.value = null;
    // Open file input
    inputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && onImageSelect) {
      onImageSelect(file);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
      <Typography variant="h5" component="div" sx={{ mb: 2, textAlign: 'center' }}>
        {shortDescription}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {Array.isArray(imageUrls) && imageUrls.map((imageUrl, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <img
              src={imageUrl}
              alt={`Uploaded content ${index + 1}`}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '4px',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Button variant="outlined" color="primary" startIcon={<CameraAltIcon />} onClick={handleTakeAnother} sx={{ mt: 2 }}>
        Take another
      </Button>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
    </Box>
  );
};

export default ImageSection;
