import React from 'react';

const Notifications = () => {
    return (
        <div>
            Notifications Page
        </div>
    );
}

export default Notifications;
