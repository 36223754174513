import React from 'react';

const Checkout = () => {
    return (
        <div>
            Checkout Page
        </div>
    );
}

export default Checkout;
