// Sell.js

import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Authentication/AuthContext';
import ImageSection from '../components/ImageSection';
import PriceSection from '../components/PriceSection';
import AddressSection from '../components/AddressSection';
import EstimatedDimensionsSection from '../components/EstimatedDimensionsSection';
import DescriptionSection from '../components/DescriptionSection';
import DetailsSection from '../components/DetailsSection';
const TAG = '[Sell]';

function Sell() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, stripeAccountId: sellerStripeAccountId, id: sellerId, address: sellerAddress } = useAuth();
  console.log(TAG, 'sellerId:', sellerId);

  const [listing, setDescriptionObject] = useState(location.state?.description || {});
  const [address, setAddress] = useState(sellerAddress);
  const [dimensions, setDimensions] = useState({ length: 0, width: 0, height: 0, weight: 0 });
  const listingId = location.state?.listingId || '';
  const coordinates = location.state?.coordinates || 'Coordinates not available';
  const [price, setPrice] = useState(listing.price_range ? listing.price_range.low : 0);
  const [imageUrls, setImageUrls] = useState(location.state?.imageUrls || []);
  const [thumbnailUrl, setThumbnailUrl] = useState(location.state?.thumbnailUrl || '');

  const extractNumber = (value) => {
    const str = String(value);
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const handleAddressChange = (part, value) => {
    setAddress(prev => ({ ...prev, [part]: value }));
  };

  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };

  useEffect(() => {
    if (listingId) {
      axios.get(`/listing/${listingId}`)
        .then((response) => {
          setDescriptionObject(response.data);
          setPrice(response.data.price);
          setImageUrls(response.data.imageUrls);
          setThumbnailUrl(response.data.thumbnailUrl || '');
          setDimensions(response.data.estimated_dimensions);
          console.log(TAG, 'Listing fetched:', response.data);

        })
        .catch((error) => {
          console.error(TAG, 'Error fetching listing:', error);
        });
    }
  }, [listingId]);

  useEffect(() => {
    const dimensionStrings = location?.state?.description?.estimated_dimensions;
    if (dimensionStrings) {
      setDimensions({
        length: extractNumber(dimensionStrings.length_cm || '0'),
        width: extractNumber(dimensionStrings.width_cm || '0'),
        height: extractNumber(dimensionStrings.height_cm || '0'),
        weight: extractNumber(dimensionStrings.weight_g || '0')
      });
    }
  }, [location?.state?.description?.estimated_dimensions]);

  useEffect(() => {
    console.log(TAG, 'imageUrls received:', imageUrls);
    if (imageUrls.length > 0) {
      console.log(TAG, 'Testing first image URL:', imageUrls[0]);
    }
  }, [imageUrls]);

  const handleList = async () => {
    const payload = {
      id: listingId,
      sellerId,
      short_description: listing.short_description,
      features_and_condition: listing.features_and_condition,
      authenticity_and_style: listing.authenticity_and_style,
      functionality_and_documentation: listing.functionality_and_documentation,
      market_insights: listing.market_insights,
      description: listing.description,
      price_range: listing.price_range,
      estimated_dimensions: dimensions,
      price,
      imageUrls,
      thumbnailUrl,
      pickupAddress: sellerAddress,
      coordinates,
      sellerStripeAccountId,
      active: token ? true : false
    };

    try {
      console.log(TAG, 'Token:', token);
      if (!token) {
        const inactiveResponse = await axios.post('/list-inactive', payload);
        if (inactiveResponse.status === 200) {
          console.log(TAG, 'Inactive listing listed:', inactiveResponse.data);
        }
        navigate('/login', { state: { isSeller: true, listingId } });
        return;
      }
    } catch (error) {
      console.error(TAG, 'Error listing inactive listing:', error);
    }

    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      console.log(TAG, 'Listing payload:', payload);
      const response = await axios.post('/list', payload, { headers });
      console.log(TAG, 'Listing listed:', response.status, response.data);

      if (response.status === 200) {
        navigate('/profile');
      } else {
        console.error(TAG, 'Failed to list listing:', response.status, response.data);
      }
    } catch (error) {
      console.error(TAG, 'Error listing listing:', error);
    }
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2} alignItems="stretch">
          {/* Image and Price Section */}
          <Grid item xs={12}>
            <Card raised sx={{ mb: 4 }}>
              <CardContent>
                <ImageSection 
                  imageUrls={imageUrls} 
                  shortDescription={listing.short_description || 'Your Images'} 
                />
                <PriceSection price={price} handlePriceChange={handlePriceChange} priceRange={listing.price_range} />
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleList} 
                  sx={{ mt: 2, width: '100%' }}
                >
                  List Item for Sale - {`$${price}`}
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Description Section */}
          <Grid item xs={12}>
            <Card raised sx={{ mb: 4 }}>
              <CardContent>
                <DescriptionSection description={listing.description} />
              </CardContent>
            </Card>
          </Grid>

          {/* Address and Estimated Dimensions Section */}
          <Grid container item spacing={2} xs={12}>
            {/* Address Section */}
            <Grid item xs={12} sm={6}>
              <Card raised sx={{ mb: 4 }}>
                <CardContent>
                  <AddressSection
                    address={address}
                    editMode={false}
                    handleAddressChange={handleAddressChange}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Estimated Dimensions Section */}
            <Grid item xs={12} sm={6}>
              <Card raised sx={{ mb: 4 }}>
                <CardContent>
                  <EstimatedDimensionsSection dimensions={dimensions} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Additional Details Sections */}
          {Object.keys(listing).map((section) => {
            // Create an array of sections you want to include:
            const allowedSections = ['features_and_condition', 'authenticity_and_style', 'functionality_and_documentation', 'market_insights'];

            // Check if the current section is within your allowed list:
            if (allowedSections.includes(section)) {
              return (
                <Grid item xs={12} sm={6} key={section}>
                  <Card raised sx={{ mb: 4 }}>
                    <CardContent>
                      <DetailsSection section={section} content={listing[section]} />
                    </CardContent>
                  </Card>
                </Grid>
              );
            } else {
              return null; // Don't render anything if the section is not allowed
            }
          })}
        </Grid>
      </Box>
    </Container>
  );
}

export default Sell;