import React from 'react';

const Feedback = () => {
    return (
        <div>
            Feedback Page
        </div>
    );
}

export default Feedback;
