import React from 'react';
import { Typography, TextField, Slider } from '@mui/material';

const PriceSection = ({ price, handlePriceChange, priceRange }) => (
  <>
    <TextField fullWidth label="Set Your Price" value={price} onChange={handlePriceChange} type="number" InputProps={{ startAdornment: <Typography sx={{ mr: 1 }}>$</Typography> }} />
    <Slider value={typeof price === 'number' ? price : 0} onChange={handlePriceChange} aria-labelledby="input-slider" min={priceRange ? priceRange.low : 0} max={priceRange ? priceRange.high : 1000} valueLabelDisplay="auto" sx={{ mb: 2 }} />
  </>
);

export default PriceSection;
