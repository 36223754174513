import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Container, Box, Typography, TextField, Button, Card, CardContent } from '@mui/material';
const TAG = "[Login]"

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    const { login } = useAuth();
    const isSeller = state?.isSeller || false;
    const listingId = state?.listingId || false;
    const orderId = state?.orderId || false;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password, listingId })
            });
            const data = await response.json();
            if (data.token) {
                // Passing address data to the login method
                login(data.token, data.stripeAccountId, data.email, data.id, data.address, listingId);
                console.log(TAG, 'Login successful!');

                if (listingId) {
                    navigate('/sell', { state: { ...state, token: data.token } });
                    return;
                }

                if (orderId) {
                    navigate('/order-confirmation', { state: { orderId } });
                    return;
                }
                navigate('/');
            } else {
                console.error('Login failed:', data.message);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card raised sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 2, textAlign: 'center' }}>
                            Login Page
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                type="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                type="password"
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                margin="normal"
                            />
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                fullWidth 
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Login
                            </Button>
                            {/* Add Link to Register Page */}
                            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                                Don't have an account?
                            <Link to="/register" state={{ isSeller: isSeller, listingId, orderId }}>Register here</Link>
                        </Typography>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default Login;
