// Landing.js
import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../Authentication/AuthContext';
import { Button, Typography, Grid, Container, useTheme, useMediaQuery } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import logo from '../../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';

function Landing() { 
    const [description, setDescription] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [coordinates, setLocation] = useState(null);
    const [address, setAddress] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [listingId, setListingId] = useState(null);
    const [abortController, setAbortController] = useState(new AbortController());
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const buttonStyles = {
        fontSize: '1rem',
        padding: theme.spacing(1, 5),
        margin: theme.spacing(1),
        width: '250px',
        boxShadow: theme.shadows[2],
    };
    
    // Function to fetch address from OpenCage Geocoder
    const fetchAddress = async (latitude, longitude) => {
        const apiKey = process.env.REACT_APP_OPENCAGEDATA_SECRET;
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(latitude)}+${encodeURIComponent(longitude)}&key=${apiKey}`;
    
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                setAddress(data.results[0].formatted);
            } else {
                console.error('No results found:', data);
                setAddress('');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('');
        }
    };
  
    // Modify the getLocation function
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    setLocation({ latitude: lat, longitude: lng });
                    await fetchAddress(lat, lng); // Fetch address
                },
                (error) => {
                    console.error('Error getting coordinates:', error);
                    setLocation(null);
                },
                { enableHighAccuracy: true, timeout: 5000 } // High accuracy and timeout options
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLocation(null);
        }
    };

    useEffect(() => {
        // getLocation();
        setListingId(uuidv4());
    }, []);

    const handleCapture = async (e) => {
        console.log('handleCapture');
        let file = e?.target?.files[0] ?? inputRef.current?.files[0];
        if (file) {
            file = inputRef.current.files[0];
        }
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImageUrl(previewUrl);
            setIsUploading(true); // Set isUploading to true to show the LoadingOverlay
    
            const formData = new FormData();
            formData.append('photo', file);
            const imageUuid = uuidv4();
            formData.append('imageUuid', imageUuid);
            formData.append('listingId', listingId);
            console.log('formData:', formData); 
    
            abortController.abort(); // Abort any ongoing request
            const newAbortController = new AbortController();
            setAbortController(newAbortController);
    
            try {
                const response = await fetch('/generate', {
                    method: 'POST',
                    body: formData,
                    signal: newAbortController.signal,
                });
    
                if (response.ok) {
                    const data = await response.json();
                    console.log('data:', JSON.stringify(data, null, 2));
                    setDescription(data.description);
                    setImageUrl(data.imageUrl);
                    navigate('/sell', { 
                      state: { 
                          description: data.description, 
                          imageUrls: data.imageUrls,
                          thumbnailUrl: data.thumbnailUrl,
                          coordinates: coordinates,
                          listingId,
                          address: address
                      } 
                    });
                } else {
                    console.error('Error uploading file:', response.statusText);
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error uploading file:', error);
                }
            } finally {
                if (!abortController.signal.aborted) {
                    setIsUploading(false); // Set isUploading to false only if not aborted
                }
            }
        }
    };    

    const handleBuyClick = () => {
        navigate('/listings');
    };

    const onTakeAnother = () => {
        console.log('Taking another picture');
        abortController.abort(); // Abort the ongoing request
        inputRef.current.value = null; // Reset the file input for a new picture
        inputRef.current.click(); // Open file input
        // Note: isUploading is not set to false here to keep the overlay open
    };    

    return (
        <>
            {isUploading && (
                <LoadingOverlay 
                    imageUrl={imageUrl} 
                    onTakeAnother={onTakeAnother} 
                    handleCapture={handleCapture}
                />
            )}
            <Container maxWidth={matches ? 'sm' : 'md'}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '90vh' }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" align="center" gutterBottom>
                            needL
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography 
                            variant="subtitle1"
                            align="center"
                            color="textSecondary"
                            gutterBottom
                        >
                            <span style={{ color: '#1976D2', fontWeight: 'bold' }}>
                                Find what you need
                            </span>
                            <br />
                            <span style={{ color: '#388E3C', fontWeight: 'bold' }}>
                                Sell what you don't
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<CameraAltIcon />}
                            onClick={() => inputRef.current.click()}
                            sx={buttonStyles}
                        >
                            Sell
                        </Button>
                        <input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            capture="camera"
                            onChange={handleCapture}
                            style={{ display: 'none' }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ShoppingCartIcon />}
                            onClick={handleBuyClick}
                            sx={buttonStyles}
                        >
                            Buy
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Landing;