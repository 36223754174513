import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications'; // Importing the notifications icon
import AccountCircle from '@mui/icons-material/AccountCircle'; // Importing the account circle icon for profile
import logo from '../../images/logo.png';

const Header = () => {
    return (
        <AppBar position="static" color="default" elevation={0}>
            <Toolbar>
                <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                    <img src={logo} alt="needL logo" style={{ marginRight: 10, height: 40 }} />
                    <Typography variant="h6" color="inherit" noWrap>
                        needL
                    </Typography>
                </Link>
                <IconButton color="inherit" component={Link} to="/profile">
                    <AccountCircle />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
