import React from 'react';
import { Typography, Box } from '@mui/material';

const DescriptionSection = ({ description }) => (
  <Box sx={{ mt: 2, mb: 4 }}>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Description</Typography>
    <Typography variant="body1" paragraph>{description}</Typography>
  </Box>
);

export default DescriptionSection;
