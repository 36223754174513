import React from 'react';
import { Typography, Box } from '@mui/material';

const DetailsSection = ({ section, content }) => (
  <Box sx={{ mt: 2, mb: 4, p: 2 }}>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
      {section.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
    </Typography>
    {typeof content === 'object' && content ? ( // Add a check for content itself
      Object.entries(content).map(([key, value]) => (
        value !== 'Unknown' && value !== 'Unavailable' && (
          <Typography key={key} variant="body2" sx={{ mb: 1 }}>
            <strong>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}:</strong> {value}
          </Typography>
        )
      ))
    ) : (
      <Typography variant="body2">{content || 'No details available'}</Typography> // Handle content being null, undefined, or empty string 
    )}
  </Box>
);

export default DetailsSection;
